import z from "zod";

import {
  authData,
  authedServiceRequest,
  authToken,
  serviceRequest,
  serviceResponse,
} from "./BaseService";
import { SUBSCRIPTION_TIERS } from "./subscription.models";

const USER_SUBSCRIPTION_TIER = ["FREE", ...SUBSCRIPTION_TIERS] as const;

export type UserSubscriptionTier = (typeof USER_SUBSCRIPTION_TIER)[number];

export const UserSchema = z.object({
  _id: z.string(),
  appleAppAccountToken: z.string().uuid(),
  createdAt: z.number().nullable(),
  discordId: z.string().optional(),
  discordUsername: z.string().optional(),
  email: z.string(),
  emailVerificationRequired: z.boolean(),
  emailVerificationToken: z.string().uuid().nullable(),
  emailVerificationTokenRequestedAt: z.date().nullable(),
  emailVerifiedAt: z.date().nullable(),
  firstName: z.string(),
  isDisabled: z.boolean(),
  lastName: z.string(),
  passwordHash: z.string().nullable(),
  resetToken: z.string().nullable(),
  resetTokenExpiresAt: z.number().nullable(),
  stripeCustomerId: z.string().nullable(),
  temperature: z.number().optional(),
  username: z.string().nullable(),
});

/**
 * Sensitive fields are omitted from API responses that are returned to the
 * client. Note that this requires using these Zod objects to parse the response
 * data in the respective service methods.
 */
export const ApiResponseUserSchema = UserSchema.omit({
  emailVerificationToken: true,
  passwordHash: true,
  resetToken: true,
  resetTokenExpiresAt: true,
  stripeCustomerId: true,
});

export type ApiResponseUser = z.infer<typeof ApiResponseUserSchema>;

export const UserWithSubscriptionSchema = UserSchema.merge(
  z.object({
    subscriptionTier: z.enum(USER_SUBSCRIPTION_TIER),
  }),
);

export const ApiResponseUserWithSubscriptionSchema =
  ApiResponseUserSchema.merge(
    z.object({
      subscriptionTier: z.enum(USER_SUBSCRIPTION_TIER),
    }),
  );

export type ApiResponseUserWithSubscription = z.infer<
  typeof ApiResponseUserWithSubscriptionSchema
>;

export const authenticationResponse = serviceResponse.merge(
  z.object({
    token: authToken.optional(),
    user: ApiResponseUserWithSubscriptionSchema.optional(),
  }),
);

export type User = z.infer<typeof UserSchema>;
export type UserWithSubscription = z.infer<typeof UserWithSubscriptionSchema>;
export type AuthenticationResponse = z.infer<typeof authenticationResponse>;

export type UserService = {
  register(request: RegisterUserRequest): Promise<RegisterUserResponse>;
  sendEmailVerificationLink(
    request: SendEmailVerificationLinkRequest,
  ): Promise<SendEmailVerificationLinkResponse>;
  verifyEmailVerificationToken(
    request: VerifyEmailVerificationTokenRequest,
  ): Promise<VerifyEmailVerificationTokenResponse>;
  setTemperature(
    request: SetTemperatureRequest,
  ): Promise<SetTemperatureResponse>;
  linkDiscordAccount(
    request: LinkDiscordAccountRequest,
  ): Promise<LinkDiscordAccountResponse>;
  unlinkDiscordAccount(
    request: UnlinkDiscordAccountRequest,
  ): Promise<UnlinkDiscordAccountResponse>;
  login(request: LoginUserRequest): Promise<LoginUserResponse>;
  oAuthLogin(request: OAuthLoginUserRequest): Promise<OAuthLoginUserResponse>;
  byEmail(request: GetUserByEmailRequest): Promise<GetUserByEmailResponse>;
  startReset(
    request: StartResetUserPasswordRequest,
  ): Promise<StartResetUserPasswordResponse>;
  finishReset(
    request: FinishResetUserPasswordRequest,
  ): Promise<FinishResetUserPasswordResponse>;
  get(request: GetUserRequest): Promise<GetUserResponse>;
  superList(request: SuperListUsersRequest): Promise<SuperListUsersResponse>;
  superImpersonate(
    request: SuperImpersonateUserRequest,
  ): Promise<SuperImpersonateUserResponse>;
  systemUser(): Promise<SystemUserResponse>;
  setUsername(request: SetUsernameRequest): Promise<SetUsernameResponse>;
  getUsername(request: GetUsernameRequest): Promise<GetUsernameResponse>;
  deleteUser(request: DeleteUserRequest): Promise<DeleteUserResponse>;
};

/** ******************************************************************************
 *  Register User
 ******************************************************************************* */

export const USER_REGISTRATION_METHOD = [
  "PASSWORD",
  "DISCORD",
  "GOOGLE",
  "APPLE",
  "LEGACY_UNKNOWN",
] as const;

export type UserRegistrationMethod = (typeof USER_REGISTRATION_METHOD)[number];

export const registerUserParams = z.object({
  discordId: z.string().optional(),
  discordUsername: z.string().optional(),
  email: z.string().email(),
  firstName: z.string(),
  lastName: z.string(),
  password: z.string().nullable(),
  registrationMethod: z.enum(USER_REGISTRATION_METHOD),
});

export const registerUserRequest = serviceRequest.merge(
  z.object({
    params: registerUserParams,
  }),
);

export const registerUserResponse = authenticationResponse;

export type RegisterUserParams = z.infer<typeof registerUserParams>;
export type RegisterUserRequest = z.infer<typeof registerUserRequest>;
export type RegisterUserResponse = z.infer<typeof registerUserResponse>;

export const registerTeamStoreParams = z.object({
  _id: z.string(),
  appleAppAccountToken: z.string().uuid(),
  email: z.string(),
  emailVerificationRequired: z.boolean(),
  firstName: z.string(),
  lastName: z.string(),
  passwordHash: z.string().nullable(),
  registrationMethod: z.enum(USER_REGISTRATION_METHOD),
  stripeCustomerId: z.string(),
  username: z.string(),
});

export type RegisterUserStoreParams = z.infer<typeof registerTeamStoreParams>;

/** ******************************************************************************
 *  Send Email Verification Link
 ******************************************************************************* */

export const sendEmailVerificationLinkRequest = authedServiceRequest;

export const sendEmailVerificationLinkResponse = serviceResponse.merge(
  z.object({
    user: ApiResponseUserSchema.optional(),
  }),
);

export type SendEmailVerificationLinkRequest = z.infer<
  typeof sendEmailVerificationLinkRequest
>;
export type SendEmailVerificationLinkResponse = z.infer<
  typeof sendEmailVerificationLinkResponse
>;

/** ******************************************************************************
 *  Link Discord Account
 ******************************************************************************* */

export const linkDiscordAccountParams = z.object({
  discordId: z.string(),
  discordUsername: z.string(),
});

export const linkDiscordAccountRequest = authedServiceRequest.merge(
  z.object({
    params: linkDiscordAccountParams,
  }),
);

export const linkDiscordAccountResponse = serviceResponse.merge(
  z.object({
    user: ApiResponseUserSchema.nullish(),
  }),
);

export type LinkDiscordAccountParams = z.infer<typeof linkDiscordAccountParams>;
export type LinkDiscordAccountRequest = z.infer<
  typeof linkDiscordAccountRequest
>;
export type LinkDiscordAccountResponse = z.infer<
  typeof linkDiscordAccountResponse
>;

/** ******************************************************************************
 *  Unlink Discord Account
 ******************************************************************************* */

export const unlinkDiscordAccountRequest = authedServiceRequest;

export const unlinkDiscordAccountResponse = serviceResponse.merge(
  z.object({
    user: ApiResponseUserSchema.nullish(),
  }),
);

export type UnlinkDiscordAccountRequest = z.infer<
  typeof unlinkDiscordAccountRequest
>;
export type UnlinkDiscordAccountResponse = z.infer<
  typeof unlinkDiscordAccountResponse
>;

/** ******************************************************************************
 *  Set Temperature
 ******************************************************************************* */

export const setTemperatureParams = z.object({
  temperature: z.number(),
});

export const setTemperatureRequest = authedServiceRequest.merge(
  z.object({
    params: setTemperatureParams,
  }),
);

export const setTemperatureResponse = serviceResponse.merge(
  z.object({
    user: ApiResponseUserSchema.nullish(),
  }),
);

export type SetTemperatureParams = z.infer<typeof setTemperatureParams>;
export type SetTemperatureRequest = z.infer<typeof setTemperatureRequest>;
export type SetTemperatureResponse = z.infer<typeof setTemperatureResponse>;

/** ******************************************************************************
 *  Verify Email Verification Link
 ******************************************************************************* */

export const verifyEmailVerificationTokenParams = z.object({
  token: z.string(),
});

export const verifyEmailVerificationTokenRequest = serviceRequest.merge(
  z.object({
    params: verifyEmailVerificationTokenParams,
  }),
);

export const verifyEmailVerificationTokenResponse = serviceResponse;

export type VerifyEmailVerificationTokenParams = z.infer<
  typeof verifyEmailVerificationTokenParams
>;
export type VerifyEmailVerificationTokenRequest = z.infer<
  typeof verifyEmailVerificationTokenRequest
>;
export type VerifyEmailVerificationTokenResponse = z.infer<
  typeof verifyEmailVerificationTokenResponse
>;

/** ******************************************************************************
 *  Login User
 ******************************************************************************* */

export const loginUserParams = z.object({
  email: z.string().email(),
  password: z.string(),
});

export const loginUserRequest = serviceRequest.merge(
  z.object({
    params: loginUserParams,
  }),
);

export const loginUserResponse = authenticationResponse;

export type LoginUserParams = z.infer<typeof loginUserParams>;
export type LoginUserRequest = z.infer<typeof loginUserRequest>;
export type LoginUserResponse = z.infer<typeof loginUserResponse>;

/** ******************************************************************************
 *  Login User From Key
 ******************************************************************************* */

export const loginUserFromKeyParams = z.object({
  apiKey: z.string(),
});

export const loginUserFromKeyRequest = serviceRequest.merge(
  z.object({
    params: loginUserFromKeyParams,
  }),
);

export const loginUserFromKeyResponse = authenticationResponse;

export type LoginUserFromKeyParams = z.infer<typeof loginUserFromKeyParams>;
export type LoginUserFromKeyRequest = z.infer<typeof loginUserFromKeyRequest>;
export type LoginUserFromKeyResponse = z.infer<typeof loginUserFromKeyResponse>;

/** ******************************************************************************
 *  Start Reset User Password
 ******************************************************************************* */

export const startResetUserPasswordParams = z.object({
  email: z.string().email(),
});

export const startResetUserPasswordRequest = serviceRequest.merge(
  z.object({
    params: startResetUserPasswordParams,
  }),
);

export const startResetUserPasswordResponse = serviceResponse.merge(
  z.object({
    success: z.boolean(),
  }),
);

export type StartResetUserPasswordParams = z.infer<
  typeof startResetUserPasswordParams
>;
export type StartResetUserPasswordRequest = z.infer<
  typeof startResetUserPasswordRequest
>;
export type StartResetUserPasswordResponse = z.infer<
  typeof startResetUserPasswordResponse
>;

/** ******************************************************************************
 *  Finish Reset User Password
 ******************************************************************************* */

export const finishResetUserPasswordParams = z.object({
  password: z.string(),
  resetToken: z.string(),
});

export const finishResetUserPasswordRequest = serviceRequest.merge(
  z.object({
    params: finishResetUserPasswordParams,
  }),
);

export const finishResetUserPasswordResponse = serviceResponse.merge(
  z.object({
    success: z.boolean(),
  }),
);

export type FinishResetUserPasswordParams = z.infer<
  typeof finishResetUserPasswordParams
>;
export type FinishResetUserPasswordRequest = z.infer<
  typeof finishResetUserPasswordRequest
>;
export type FinishResetUserPasswordResponse = z.infer<
  typeof finishResetUserPasswordResponse
>;

/** ******************************************************************************
 *  Get User
 ******************************************************************************* */

export const getUserParams = z.undefined();

export const getUserRequest = authedServiceRequest.merge(
  z.object({
    params: getUserParams,
  }),
);

export const getUserResponse = serviceResponse.merge(
  z.object({
    user: ApiResponseUserWithSubscriptionSchema.nullish(),
  }),
);

export type GetUserParams = z.infer<typeof getUserParams>;
export type GetUserRequest = z.infer<typeof getUserRequest>;
export type GetUserResponse = z.infer<typeof getUserResponse>;

/** ******************************************************************************
 *  Set Username
 ******************************************************************************* */

export const setUsernameParams = z.object({
  username: z.string(),
});

export type SetUsernameParams = z.infer<typeof setUsernameParams>;

export const setUsernameRequest = authedServiceRequest.merge(
  z.object({
    params: setUsernameParams,
  }),
);

export const setUsernameResponse = serviceResponse.merge(
  z.object({
    user: ApiResponseUserSchema.nullish(),
  }),
);

export type SetUsernameRequest = z.infer<typeof setUsernameRequest>;
export type SetUsernameResponse = z.infer<typeof setUsernameResponse>;

/** ******************************************************************************
 *  Get Username
 ******************************************************************************* */

export const getUsernameParams = z.object({
  userId: z.string(),
});

export const getUsernameRequest = serviceRequest.merge(
  z.object({
    params: getUsernameParams,
  }),
);

export const getUsernameResponse = serviceResponse.merge(
  z.object({
    username: z.string().nullable(),
  }),
);

export type GetUsernameParams = z.infer<typeof getUsernameParams>;
export type GetUsernameRequest = z.infer<typeof getUsernameRequest>;
export type GetUsernameResponse = z.infer<typeof getUsernameResponse>;

/** ******************************************************************************
 *  Super List Users
 ******************************************************************************* */

export const superListUsersParams = z.object({
  _id: z.string().optional(),
  cursor: z
    .object({
      offset: z.number(),
      pageSize: z.number(),
    })
    .nullish(),
  email: z.string().optional(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
});

export const superListUsersRequest = authedServiceRequest.merge(
  z.object({
    params: superListUsersParams,
  }),
);

export const superListUsersResponse = serviceResponse.merge(
  z.object({
    total: z.number().optional(),
    users: z.array(ApiResponseUserSchema).optional(),
  }),
);

export type SuperListUsersParams = z.infer<typeof superListUsersParams>;
export type SuperListUsersRequest = z.infer<typeof superListUsersRequest>;
export type SuperListUsersResponse = z.infer<typeof superListUsersResponse>;

/** ******************************************************************************
 *  Super Impersonate User
 ******************************************************************************* */

export const superImpersonateUserParams = z.object({
  userId: z.string(),
});

export const superImpersonateUserRequest = authedServiceRequest.merge(
  z.object({
    params: superImpersonateUserParams,
  }),
);

export const superImpersonateUserResponse = authenticationResponse;

export type SuperImpersonateUserParams = z.infer<
  typeof superImpersonateUserParams
>;
export type SuperImpersonateUserRequest = z.infer<
  typeof superImpersonateUserRequest
>;
export type SuperImpersonateUserResponse = z.infer<
  typeof superImpersonateUserResponse
>;

/** ******************************************************************************
 *  System User
 ******************************************************************************* */

export const systemUserResponse = z.object({
  auth: authData,
  user: ApiResponseUserSchema,
});

export type SystemUserResponse = z.infer<typeof systemUserResponse>;

/** ******************************************************************************
 *  OAuth
 ******************************************************************************* */

export const zAuthenticationStrategy = z.enum([
  "BasicRegister",
  "BasicLogin",
  "Google",
  "GoogleIOS",
  "Discord",
  "Github",
  "LinkedIn",
  "AppleIOS",
]);

export type AuthenticationStrategy = z.infer<typeof zAuthenticationStrategy>;

/** ******************************************************************************
 *  OAuth Login User
 ******************************************************************************* */

export const oAuthloginUserParams = z.object({
  email: z.string().email(),
});

export const oAuthloginUserRequest = serviceRequest.merge(
  z.object({
    params: oAuthloginUserParams,
  }),
);

export const oAuthloginUserResponse = authenticationResponse;

export type OAuthLoginUserParams = z.infer<typeof oAuthloginUserParams>;
export type OAuthLoginUserRequest = z.infer<typeof oAuthloginUserRequest>;
export type OAuthLoginUserResponse = z.infer<typeof oAuthloginUserResponse>;

/** ******************************************************************************
 *  Get User By Email
 ******************************************************************************* */

export const getUserByEmailParams = z.object({
  userEmail: z.string(),
});

export const getUserByEmailRequest = serviceRequest.merge(
  z.object({
    params: getUserByEmailParams,
  }),
);

export const getUserByEmailResponse = serviceResponse.merge(
  z.object({
    user: ApiResponseUserWithSubscriptionSchema.nullish(),
  }),
);

export type GetUserByEmailParams = z.infer<typeof getUserByEmailParams>;
export type GetUserByEmailRequest = z.infer<typeof getUserByEmailRequest>;
export type GetUserByEmailResponse = z.infer<typeof getUserByEmailResponse>;

/** ******************************************************************************
 *  Delete User
 ******************************************************************************* */

export const deleteUserRequest = authedServiceRequest;

export const deleteUserResponse = serviceResponse;

export type DeleteUserRequest = z.infer<typeof deleteUserRequest>;
export type DeleteUserResponse = z.infer<typeof deleteUserResponse>;
